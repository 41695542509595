import {dangerColor} from '../../assets/jss/nextjs-material-dashboard';
import moment from 'moment/moment';
import {Box, IconButton, Paper, Tooltip} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DeleteOutlined, Notifications, OilBarrel, Schedule, Thermostat, Timer} from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {unitsByParams} from '../../utils';
import {deleteCameraDeviceImage} from '../../api';
import Swal from 'sweetalert2';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMapContext} from '../../context/map/mapContext';
import {useCameraContext} from '../../context/camera/cameraContext';

export default function PhotoComponent({metadata, device}) {
  const {t, i18n} = useTranslation();
  const {state: {selectedDevice}} = useMapContext();
  const {clientId} = useConfig().state.config;
  const {serialNo} = selectedDevice || device;
  const {state: {images}, dispatch: cameraDispatch} = useCameraContext();

  const setImages = images => cameraDispatch({type: 'set-images', images});

  const [elevated, setElevated] = React.useState(false);
  const [isDeleteBtnClick, setIsDeleteBtnClick] = useState(false);
  const {imgUrl, thumbUrl, imgUrlIR, thumbUrlIR, timestamp, type, oil, temp, closestMeasurementTimestamp} = metadata;
  const [animateOut, setAnimateOut] = useState(false);
  const [loading, setLoading] = useState(true);

  const img = imgUrl || imgUrlIR;
  const thumb = thumbUrl || thumbUrlIR;

  const handleCloseClick = () => {
    setAnimateOut(true);
    setTimeout(() => {
      setIsDeleteBtnClick(false);
      setAnimateOut(false);
    }, 500);
  };

  const onDeletePhoto = timestamp => {
    setLoading(true);
    deleteCameraDeviceImage(clientId, serialNo, timestamp)
      .then(res => {
        setImages(images.filter(img => img.timestamp !== timestamp));
        Swal.fire({
          title: 'Success',
          text: t('cameraDevicePhoto.photoHasBeenSuccessfullyDeleted'),
          icon: 'success',
          customClass: {
            container: 'my-swal'
          },
        });
      })
      .catch(err => {
        Swal.fire({
          title: t('swal.errorMessage'),
          html: err.response?.data,
          icon: 'error',
          customClass: {container: 'my-swal'}
        });
      })
      .finally(() => setLoading(false));
  };

  const handleMouseEnter = () => {
    setElevated(true);
  };

  const handleMouseLeave = () => {
    setElevated(false);
  };

  return <Paper
    elevation={0}
    display={'flex'}
    flexDirection={'column'}
    style={{position: 'relative', padding: 10}}
  >
    <Box mb={1} display={'flex'} justifyContent={'space-between'}>
      <Typography
        variant={'button'}
        textTransform={'capitalize'}
        style={{color: 'grey', display: 'flex', gap: 1}}
      >
        {moment(timestamp).format('LLLL')}
      </Typography>
    </Box>
    {type === 'episode' && (
      <Box style={{position: 'absolute', right: 5, top: 5}}>
        <Tooltip title={t('cameraDevicePhoto.cameraDevicePhoto')}>
          <IconButton style={{color: 'grey'}}>
            <Notifications style={{color: 'red', width: 20, height: 20}}/>
          </IconButton>
        </Tooltip>
      </Box>
    )}
    {type === 'custom' && (
      <Box style={{
        position: 'absolute',
        right: 5,
        top: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        {isDeleteBtnClick ? (
          <Box
            sx={{
              position: 'relative',
              animation: animateOut ? 'slideOut 0.5s forwards' : isDeleteBtnClick ? 'slideIn 0.5s forwards' : 'none',
            }}
          >
            <Tooltip title={t('cameraDevicePhoto.willBePermanentlyDeleted')}>
              <IconButton onClick={() => onDeletePhoto(timestamp)}>
                <DoneIcon color="error"/>
              </IconButton>
            </Tooltip>
            <IconButton onClick={handleCloseClick}>
              <CloseIcon color="info"/>
            </IconButton>
          </Box>
        ) : (
          <Tooltip title={t('cameraDevicePhoto.photoTakenAdHocYouCanDeleteThisPhoto')}>
            <IconButton
              style={{color: 'grey'}}
              onClick={() => setIsDeleteBtnClick(true)}
            >
              <DeleteOutlined style={{width: 20, height: 20}}/>
            </IconButton>
          </Tooltip>
        )}
      </Box>
    )}

    <Box display={'flex'} alignItems={'flex-end'}>
      <Paper
        onMouseEnter={() => handleMouseEnter('rgb')}
        onMouseLeave={handleMouseLeave}
        elevation={elevated ? 5 : 0}
        style={{cursor: 'pointer'}}
      >
        <a href={img} target={'_blank'}>
          <img
            style={{
              borderRadius: 5,
              borderColor: type === 'episode' ? dangerColor[2] : 'transparent',
              borderWidth: 1,
              borderStyle: 'solid'
            }}
            src={thumb || imgUrl || imgUrlIR}
            alt={`${t('cameraDevicePhoto.photoFrom')} ${moment(timestamp).format('LLLL')}`}/>
        </a>
      </Paper>

    </Box>
    <Box display={'flex'} gap={3} mt={1}>
      {oil !== null && oil !== undefined && <Tooltip title={t('cameraDevicePhoto.levelOfPetroleumSubstancesInWater')}>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <OilBarrel color={'primary'} sx={{width: 20, height: 20}}/>
          <Typography fontSize={12} fontWeight={'bold'} color={'primary'}>
            {oil?.toFixed(1)} {unitsByParams('oil')}
          </Typography>
        </Box>
      </Tooltip>}
      {temp !== null && temp !== undefined && <Tooltip title={t('cameraDevicePhoto.waterTemperature')}>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Thermostat color={'primary'} sx={{width: 20, height: 20}}/>
          <Typography fontSize={12} fontWeight={'bold'} color={'primary'}>
            {temp?.toFixed(1)} {unitsByParams('temp')}
          </Typography>
        </Box>
      </Tooltip>}
      {closestMeasurementTimestamp &&
        <Tooltip title={t('cameraDevicePhoto.measurementTime')}>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Schedule color={'primary'} sx={{width: 18, height: 18}}/>
          <Typography fontSize={12} fontWeight={'normal'} color={'primary'}>
            {moment(closestMeasurementTimestamp).format('HH:mm')}
          </Typography>
        </Box></Tooltip>}
    </Box>

  </Paper>;
}


// const RightPhotoIcon = ({type, timestamp, deletePhoto}) => {
//   if (type === 'custom') {
//     return ;
//   } else if (type === 'auto') {
//     return <Tooltip placement={'bottom'}
//                     title={'Rutynowe zdjęcie przy pomiarze - zostanie automatycznie usunięte ' + moment(timestamp).add(30, 'days').fromNow()}>
//       <IconButton
//         color={'primary'}
//         // onClick={() => deletePhoto(timestamp)}
//       >
//         <InfoOutlined sx={{width: 20, height: 20}}/>
//       </IconButton>
//     </Tooltip>;
//   } else if (type === 'episode') {
//     return ;
//   }
// };

// const RightPhotoIcon = ({type, timestamp, deletePhoto}) => {
//   if (type === 'custom') {
//     return <Tooltip placement={'bottom'} title={'Usuń zdjęcie'}>
//       <IconButton
//         sx={{color: 'grey'}}
//         onClick={() => deletePhoto(timestamp)}
//       >
//         <DeleteOutlined sx={{width: 20, height: 20}}/>
//       </IconButton>
//     </Tooltip>;
//   } else if (type === 'auto') {
//     return <Tooltip placement={'bottom'}
//                     title={'Rutynowe zdjęcie przy pomiarze - zostanie automatycznie usunięte ' + moment(timestamp).add(30, 'days').fromNow()}>
//       <IconButton
//         color={'primary'}
//         // onClick={() => deletePhoto(timestamp)}
//       >
//         <InfoOutlined sx={{width: 20, height: 20}}/>
//       </IconButton>
//     </Tooltip>;
//   } else if (type === 'episode') {
//     return <Tooltip placement={'bottom'} title={'Zdjęcie powiązane z przekroczeniem.'}>
//       <IconButton
//         sx={{color: 'grey'}}
//       >
//         <Notifications sx={{color: dangerColor[2], width: 20, height: 20}} color={'primary'}/>
//       </IconButton>
//     </Tooltip>;
//   }
// };
